@import "minima";
body {
  font-family: 'EB Garamond', serif;
  font-size: 18px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 15px;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.commento-root {
  width: 75% !important;
  clear: left;
  margin: auto;
}

#mc_embed_signup {
  background: unset !important;
}

// Make the "Your Name" input in Commento more noticeable.
input[type=text]::placeholder {
  color: black !important;
  font-weight: bolder;
}
#commento-guest-details-input-root {
  width: 100%;
}

// Hide the "Markdown Help" button.
#commento-markdown-button-root {
  visibility: hidden;
}